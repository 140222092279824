import React, { Suspense } from "react";
import { OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
// import Shoe from "./Shoe";
import Network from "./Network";
import styled from "styled-components";

const Desc = styled.div`
  width: 300px;
  height: 140px;
  padding: 12px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 200px;
  right: 100px;

  @media only screen and (max-width: 768px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const Development = () => {
  return (
    <>
      <Canvas className="hidden md:block" camera={{ position: [0, 0, 10] }}>
        <Suspense fallback={null}>
          <Stage environment="city" intensity={0.6}>
            <Network />
          </Stage>
          <OrbitControls enableZoom={false} autoRotate />
        </Suspense>
      </Canvas>
      <Desc className="hidden md:block">
        We design products with a strong focus on both world class design and
        ensuring your product is a market success.
      </Desc>
    </>
  );
};

export default Development;
